import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'
import Layout from "../components/App/Layout"
import NavbarTwo from "../components/App/NavbarTwo"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ProjectStartArea from '../components/App/ProjectStartArea';

const FAQ = () => {
    return (
        <Layout>
            <NavbarTwo />
            <PageBanner
                pageTitle="FAQ"
                homePageText="Home"
                homePageUrl="/"
                activePageText="FAQ"
            />
            <div className="ptb-100">
                <div className="container">
                    <div className="faq-accordion">
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q1. Power Indicator light is off
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        <h6>POSSIBLE REASON</h6>
                                        <ul>
                                            <li>Something wrong with the connection of the power adapter</li>
                                            <li>Without the power adap-ter, your battery is weak</li>
                                            <li>The AC power socket is powered off</li>
                                        </ul>


                                        <h6>POSSIBLE SOLUTION</h6>

                                        <ul>
                                            <li>Check the two ends of the power adapter, make sure it is connected to the CN438 and AC power socket firmly</li>
                                            <li>Charge your battery by putting the battery in the CN438 and plug the AC adapter Make sure the power socket is powered on</li>
                                        </ul>
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q2. Weak signal for a long time
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        <h6>POSSIBLE REASON</h6>

                                        <ul>
                                            <li>Signal coverage is poor</li>
                                        </ul>


                                        <h6>POSSIBLE SOLUTION</h6>
                                        <ul>
                                            <li>Rotate the CN438 or antenna</li>
                                            <li>Move the CN438 to the place near window or to a higher place</li>
                                        </ul>

                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q3. The computer indi-cates that it doesn't connect to the internet
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        <h6>POSSIBLE REASON</h6>

                                        <ul>
                                            <li>Something is wrong with the connection of the Ethernet cable</li>
                                            <li>Something is wrong with the connection of USB cable</li>
                                        </ul>


                                        <h6>POSSIBLE SOLUTION</h6>
                                        <ul>
                                            <li>Check the two ends of the Ethernet cable, and make sure the Ethernet cable connects to the CN438 and computer firmly</li>
                                            <li>Replace the Ethernet cable</li>
                                            <li>Check the two ends of the USB cable, and make sure the USB cable connects to the CN438 and computer firmly</li>
                                            <li>Replace the USB cable</li>
                                            <li>Move the CN438 to the place near window or to a higher place</li>
                                        </ul>
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q4. The signal is normal and the computer indicates that it con-nects to the internet, but actually can't surf the internet
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        <h6>POSSIBLE REASON</h6>

                                        <ul>
                                            <li>The network settings are not correct</li>
                                        </ul>


                                        <h6>POSSIBLE SOLUTION</h6>
                                        <ul>
                                            <li>Check the settings provided by your service provider to make sure that the access method, account, password etc. are correct</li>
                                        </ul>

                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q5. Pick up the phone, but there isn't any dial tone
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        <h6>POSSIBLE REASON</h6>

                                        <ul>
                                            <li>Something wrong with the connection of the phone line</li>
                                            <li>Power adapter isn't con-nected</li>
                                            <li>There is the Low battery</li>
                                        </ul>


                                        <h6>POSSIBLE SOLUTION</h6>
                                        <ul>
                                            <li>Check the two ends of the phone line, and make sure the phone line is con-nected to the CN438 and telephone firmly</li>
                                            <li>Connect the CN438 power adapter</li>
                                            <li>Charge your battery</li>
                                        </ul>

                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </div>
            <ProjectStartArea />
            <Footer />
        </Layout>
    );
}

export default FAQ